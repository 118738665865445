.container {
    padding: 0;
    background-color: #f0f0f0;
  }
  
.main {
    min-height: 76vh;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }

.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }   
.sidebarInner{
    background-color: white;
    overflow-y: scroll;
    max-height: 400px;
    border: 1px solid #d1d1d1;
    padding: 8px;
    margin-top: 10px;
}

.code {
    background-color: white;
    padding: 0.75rem;
    font-size: .8rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
      Bitstream Vera Sans Mono, Courier New, monospace;
  }

  .codeinput {
      border: 2px solid #696969;
      border-radius: 4px;
      min-width: 370px;
      width: 100%;
      background-color: white;
  }

  .primarybtn {
      background-color: rgb(47, 143, 106);
      color: white;
      padding: 10px;
      font-size: 1.1rem;
      border: 1px solid rgb(47, 143, 106);
      border-radius: 4px;
  }

  .primarybtn:hover {
    background-color: rgb(43, 121, 91);
  }

  .dangerbtn {
    background-color: rgb(143, 53, 47);
    color: white;
    padding: 10px;
    font-size: 1.1rem;
    border: 1px solid rgb(143, 53, 47);
    border-radius: 4px;
  }

  .dangerbtn:hover {
    background-color: rgb(90, 33, 29);
  }

  .btn{
    border: none;
    border-radius: 3px;
    padding: 5px;
  }
  .btn:hover{
    box-shadow: 0px 5px 8px rgba(126, 126, 126, 0.4);
  }
  .btn:active{
    box-shadow: none;
  }

  .rowspaced {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
  }

  .linkbtndisabled{
    color: gray;
    text-decoration: none;
    font-size: 1.3rem;
    background-color: #f0f0f0;
    border: none;
  }

  .linkbtn {
    color: #30629c;
    text-decoration: none;
    font-size: 1.3rem;
    background-color: #f0f0f0;
    border: none;
  }
  
  .linkbtn:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .codecontainer {
      background-color: white;
      border: 1px solid gray;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-width: 500px;
      white-space: pre-wrap;
  }

  .break{
    flex-basis: 100%;
    height: 0;
  }

  .selection {
      background-color: #30629c;
      color: white;
  }

  .var0{
    background-color: #2b8a44;
    color: white;
  }
  .attr0{
    background-color: #D8BFD8;
  }
  .attr1{
    background-color: #af8dae;
  }
  .attr2{
    background-color: #673147;
    color: white;
  }
  .attr3{
    background-color: #770737;
    color: white;
  }
  .attr4{
    background-color: #800080;
    color: white;
  }