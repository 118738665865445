.header{
    background-color: white;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2rem;
  }
  
  .headerText{
    color: rgb(109, 109, 109);
    font-size: 1rem;
    font-weight: 800;
    margin-left: 15px;
  }
 
  /* .headerText:hover{
    color: rgb(185, 185, 185);
    cursor: pointer;
  }
  .headerText:active{
    color: rgb(109, 109, 109);
  } */
.footer{
  background-color: white;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
}
  .footerText{
    color: rgb(109, 109, 109);
    margin-left: 15px;
  }